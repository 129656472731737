import { useCallback, useEffect, useRef } from 'react'

export default function useRafCallback(cb: (dt: number) => void) {
  const previousTimeRef = useRef<number | undefined>()
  const rafRef = useRef<number>(0)
  const callbackRef = useRef<(dt: number) => void>(cb)

  // Gets the latest callback value without causing rerenders.
  // See: https://github.com/streamich/react-use/blob/master/docs/useLatest.md
  callbackRef.current = cb

  const rafCallback: FrameRequestCallback = useCallback(time => {
    const previousTime = previousTimeRef.current
    if (previousTime !== undefined) {
      const deltaTime = time - previousTime
      callbackRef.current(deltaTime)
    }
    previousTimeRef.current = time
    rafRef.current = requestAnimationFrame(rafCallback)
  }, [])

  useEffect(() => {
    rafRef.current = requestAnimationFrame(rafCallback)

    return () => cancelAnimationFrame(rafRef.current)
  }, [rafCallback])
}
